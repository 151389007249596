import {
  React,
  connect,
  reduxForm,
  FormPanel,
  showModal,
  ModalGlobal,
  getDataParams,
  convertDate,
  setItem,
  ToastNotification,
  hideModal,
  getItem,
  Tabel,
  JSONToCSVConvertor,
  Field,
  ReanderSelect,
  removeItem
} from "components/helpers/library";
import HeadModalTrancate from "./HeadModalTrancate";
import TotalPembelian from "./TotalPembelian";
import TotalPenjualan from "./TotalPenjualan";
class Truncate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selected: [],
      trx: "PENJUALAN",
      databarang: [],
      columnsPembelian: [
        {
          dataField: "no",
          text: "no",
          hidden: true
        },
        {
          dataField: "tgl_system",
          text: "Tanggal"
        },
        {
          dataField: "no_faktur_beli",
          text: "No Faktur Beli"
        },
        {
          dataField: "nama_barang",
          text: "Nama Barang"
        },

        {
          dataField: "kondisi",
          text: "Kondisi"
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right">{cell.toFixed(3)}</div>;
          }
        },
        {
          dataField: "harga_total",
          text: "Harga",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right">{cell.toLocaleString("kr-KO")}</div>
            );
          }
        }
      ],
      columns: [
        {
          dataField: "no",
          text: "no",
          hidden: true
        },
        {
          dataField: "tgl_system",
          text: "Tanggal"
        },
        {
          dataField: "kode_barcode",
          text: "Barcode"
        },
        {
          dataField: "no_faktur_jual",
          text: "No Faktur"
        },
        {
          dataField: "nama_barang",
          text: "Nama Barang"
        },
        {
          dataField: "nama_customer",
          text: "Nama Customer"
        },
        {
          dataField: "berat",
          text: "Berat",
          headerClasses: "text-right",
          formatter: (cell) => {
            return <div className="text-right">{cell.toFixed(3)}</div>;
          }
        },
        {
          dataField: "ongkos",
          text: "Ongkos",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right">{cell?.toLocaleString("kr-KO")}</div>
            );
          }
        },
        {
          dataField: "harga_jual",
          text: "Harga Jual",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right">{cell?.toLocaleString("kr-KO")}</div>
            );
          }
        },
        {
          dataField: "harga_total",
          text: "Jumlah Rp",
          headerClasses: "text-right",
          formatter: (cell) => {
            return (
              <div className="text-right">{cell?.toLocaleString("kr-KO")}</div>
            );
          }
        },
        {
          dataField: "status_gold",
          text: "Status"
        }
      ]
    };
    // this.saveData.bind(this);
  }

  modalTruncate() {
    this.props.dispatch(showModal());
    this.props.change("status_gold", "ALL");
    setItem("filter_status", "ALL");
  }

  handleSubmit(data) {
    let row = {
      kode_gudang: data.gudang,
      tgl_from: convertDate(data.tgl_awal || new Date()),
      tgl_to: convertDate(data.tgl_akhir || new Date())
      // jumlah_rp_gold: Number(data.jumlah_rp_gold || 0),
      // start_range_gold: Number(data.start_range_gold || 0),
      // end_range_gold: Number(data.end_range_gold || 0),
      // jumlah_rp_non_gold: Number(data.jumlah_rp_non_gold || 0),
      // start_range_non_gold: Number(data.start_range_non_gold || 0),
      // end_range_non_gold: Number(data.end_range_non_gold || 0),
    };
    let url = "";
    console.log(data);
    if (data.trx === "PENJUALAN") {
      url = "penjualan/truncate";
    } else {
      url = "pembelian/truncate";
    }
    setItem("trx", data.trx);
    this.setState({
      isLoading: true
    });
    removeItem("data_trancate_pembelian");
    removeItem("data_trancate_penjualan");
    removeItem("data_trancate_penjualan_baru");
    removeItem("data_trancate_pembelian_baru");
    this.setState({
      databarang: [],
      selected: []
    });

    getDataParams(url, row)
      .then((res) => {
        setItem("trx", data.trx);
        this.setState({
          trx: data.trx
        });

        // return false
        if (data.trx === "PENJUALAN") {
          if (
            res.data.dataJualGold.length === 0 &&
            res.data.dataJualNonGold.length === 0
          ) {
            ToastNotification("info", "Data Backup Tidak Tersedia");
            this.setState({
              isLoading: false
            });
            setItem("data_trancate_penjualan", []);
            return false;
          }

          // let data = [...res.data.dataJualGold, ...res.data.dataJualNonGold];
          let datagold = res.data.dataJualGold.map((list, index) => {
            let row = {
              // no: index,
              ...list,
              status_cek: true
            };
            return row;
          });

          let dataNonGold = res.data.dataJualNonGold.map((list, index) => {
            let row = {
              // no: index,
              ...list,
              status_cek: true
            };
            return row;
          });

          let data = [...datagold, ...dataNonGold].map((list, index) => {
            let row = {
              no: index,
              ...list
            };
            return row;
          });
          // console.log(data)

          // let hasil = data.map((list, index) => {
          //   return index;
          // });
          // this.setState({
          //   selected: hasil,
          // });

          // console.log(this.state.selected)

          console.log(data);
          this.setState({
            databarang: data
          });
          setItem("data_trancate_penjualan", data);
        } else {
          if (
            res.data.dataBeliGold.length === 0 &&
            res.data.dataBeliNonGold.length === 0
          ) {
            ToastNotification("info", "Data Backup Tidak Tersedia");
            this.setState({
              isLoading: false
            });
            setItem("data_trancate_pembelian", []);
            return false;
          }

          // let data = [...res.data.dataJualGold, ...res.data.dataJualNonGold];
          let datagold = res.data.dataBeliGold.map((list, index) => {
            let row = {
              // no: index,
              ...list,
              status_cek: true
            };
            return row;
          });

          let dataNonGold = res.data.dataBeliNonGold.map((list, index) => {
            let row = {
              // no: index,
              ...list,
              status_cek: true
            };
            return row;
          });

          let data = [...datagold, ...dataNonGold].map((list, index) => {
            let row = {
              no: index,
              ...list
            };
            return row;
          });
          // let hasil = data.map((list, index) => {
          //   return index;
          // });
          // this.setState({
          //   selected: hasil
          // });

          // console.log(this.state.selected)
          this.setState({
            databarang: data
          });
          setItem("data_trancate_pembelian", data);
        }
        this.props.dispatch(hideModal());
        this.setState({
          isLoading: false
        });
      })
      .catch((err) => {
        console.log(err);

        ToastNotification(
          "info",
          "Terjadi Kesalhaan Saat Merequest Data Keserver, Silahkan Coba Lagi"
        );
        this.setState({
          isLoading: false
        });
      });
  }
  hapus() {
    this.setState({
      isLoading: true
    });
    setTimeout(() => {
      removeItem("data_trancate_penjualan");
      removeItem("data_trancate_pembelian");
      setItem("data_trancate_penjualan_baru", []);
      setItem("data_trancate_pembelian_baru", []);
      setItem("trx", "");
      setItem("filter_status", "");
      this.setState({
        isLoading: false,
        databarang: []
      });
    }, 200);
  }
  componentDidMount() {
    if (getItem("trx") === "PEMBELIAN") {
      // data_trancate_pembelian
      let data = getItem("data_trancate_pembelian").map((list) => {
        let row = {
          ...list,
          status_cek: true
        };
        return row;
      });
      setItem("data_trancate_pembelian", data);

      // let hasil = getItem("data_trancate_pembelian").map((list, index) => {
      //   return index;
      // });
      // this.setState({
      //   selected: hasil,
      // });
    } else {
      let data = getItem("data_trancate_penjualan").map((list) => {
        let row = {
          ...list,
          status_cek: true
        };
        return row;
      });
      setItem("data_trancate_penjualan", data);

      // let hasil = getItem("data_trancate_penjualan").map((list, index) => {
      //   return index;
      // });
      // this.setState({
      //   selected: hasil,
      // });
    }
  }

  handleOnSelect(row, isSelect, rowIndex, e) {
    this.setState({
      isLoading: false
    });
    if (isSelect === true) {
      let select = this.state.selected;
      select.push(rowIndex);
      this.setState({
        selected: select
      });

      let trx = getItem("trx");
      let statusFilter = getItem("filter_status");
      if (trx === "PENJUALAN") {
        if (statusFilter === "GOLD") {
          let data = this.state.databarang.find(
            (list) =>
              list.no_faktur_jual === row.no_faktur_jual &&
              list.status_gold === "GOLD"
          );
          let databaru = getItem("data_trancate_penjualan_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_jual === row.no_faktur_jual
          );
          if (hasil_cek === -1) {
            databaru.push(data);
            console.log(data);
            setItem("data_trancate_penjualan_baru", databaru);
          } else {
            ToastNotification("info", "Data Sudah Terpilih");
          }
        } else if (statusFilter === "NONGOLD") {
          let data = this.state.databarang.find(
            (list) =>
              list.no_faktur_jual === row.no_faktur_jual &&
              list.status_gold === "NONGOLD"
          );
          let databaru = getItem("data_trancate_penjualan_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_jual === row.no_faktur_jual
          );
          if (hasil_cek === -1) {
            databaru.push(data);
            setItem("data_trancate_penjualan_baru", databaru);
          } else {
            ToastNotification("info", "Data Sudah Terpilih");
          }
        } else {
          let data = this.state.databarang.find(
            (list) => list.no_faktur_jual === row.no_faktur_jual
          );
          let databaru = getItem("data_trancate_penjualan_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_jual === row.no_faktur_jual
          );
          if (hasil_cek === -1) {
            databaru.push(data);
            setItem("data_trancate_penjualan_baru", databaru);
          } else {
            ToastNotification("info", "Data Sudah Terpilih");
          }
        }
      } else {
        if (statusFilter === "GOLD") {
          let data = this.state.databarang.find(
            (list) =>
              list.no_faktur_beli === row.no_faktur_beli &&
              list.status_gold === "GOLD"
          );
          let databaru = getItem("data_trancate_pembelian_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_beli === row.no_faktur_beli
          );
          if (hasil_cek === -1) {
            databaru.push(data);
            console.log(data);
            setItem("data_trancate_pembelian_baru", databaru);
          } else {
            ToastNotification("info", "Data Sudah Terpilih");
          }
        } else if (statusFilter === "NONGOLD") {
          let data = this.state.databarang.find(
            (list) =>
              list.no_faktur_beli === row.no_faktur_beli &&
              list.status_gold === "NONGOLD"
          );
          let databaru = getItem("data_trancate_pembelian_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_beli === row.no_faktur_beli
          );
          if (hasil_cek === -1) {
            databaru.push(data);
            setItem("data_trancate_pembelian_baru", databaru);
          } else {
            ToastNotification("info", "Data Sudah Terpilih");
          }
        } else {
          let data = this.state.databarang.find(
            (list) => list.no_faktur_beli === row.no_faktur_beli
          );
          let databaru = getItem("data_trancate_pembelian_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_beli === row.no_faktur_beli
          );
          if (hasil_cek === -1) {
            databaru.push(data);
            setItem("data_trancate_pembelian_baru", databaru);
          } else {
            ToastNotification("info", "Data Sudah Terpilih");
          }
        }
      }
    } else {
      let select = this.state.selected;
      let ind = select.findIndex((list) => list === rowIndex);
      select.splice(ind, 1);
      this.setState({
        selected: select
      });

      let trx = getItem("trx");

      if (trx === "PENJUALAN") {
        if (
          getItem("data_trancate_penjualan_baru") === null ||
          getItem("data_trancate_penjualan_baru").length === 0
        ) {
        } else {
          let databaru = getItem("data_trancate_penjualan_baru") || [];
          let cekIndex = databaru.findIndex(
            (list) => list.no_faktur_jual === row.no_faktur_jual
          );
          databaru.splice(cekIndex, 1);
          setItem("data_trancate_penjualan_baru", databaru);
        }
      } else {
        if (
          getItem("data_trancate_pembelian_baru") === null ||
          getItem("data_trancate_pembelian_baru").length === 0
        ) {
        } else {
          let databaru = getItem("data_trancate_pembelian_baru") || [];
          let cekIndex = databaru.findIndex(
            (list) => list.no_faktur_beli === row.no_faktur_beli
          );
          databaru.splice(cekIndex, 1);
          setItem("data_trancate_pembelian_baru", databaru);
        }
      }
    }
  }
  handleOnSelectAll(isSelect, data, rowIndex, e) {
    let datalocal = [];
    let trx = getItem("trx");
    let filterStatus = getItem("filter_status");
    // let datalocal = []

    if (trx === "PEMBELIAN") {
      if (filterStatus === "GOLD") {
        datalocal = getItem("data_trancate_pembelian").filter(
          (list) => list.status_gold === "GOLD"
        );
      } else if (filterStatus === "NONGOLD") {
        datalocal = getItem("data_trancate_pembelian").filter(
          (list) => list.status_gold === "NONGOLD"
        );
      } else {
        datalocal = getItem("data_trancate_pembelian");
      }
    } else if (trx === "PENJUALAN") {
      if (filterStatus === "GOLD") {
        datalocal = getItem("data_trancate_penjualan").filter(
          (list) => list.status_gold === "GOLD"
        );
      } else if (filterStatus === "NONGOLD") {
        datalocal = getItem("data_trancate_penjualan").filter(
          (list) => list.status_gold === "NONGOLD"
        );
      } else {
        // console.log('else')
        datalocal = getItem("data_trancate_penjualan");
      }
    }
    let hasil = datalocal.map((list, index) => {
      return index;
    });
    this.setState({
      selected: hasil
    });

    if (isSelect === true) {
      if (trx === "PEMBELIAN") {
        let myfileter = datalocal.filter(
          (list, index) =>
            hasil.findIndex((list2, index2) => index2 === index) > -1
        );
        myfileter.forEach((row) => {
          let databaru = getItem("data_trancate_pembelian_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_beli === row.no_faktur_beli
          );
          if (hasil_cek === -1) {
            let hasil = {
              ...row
            };
            databaru.push(hasil);
            setItem("data_trancate_pembelian_baru", databaru);
          }
        });
      } else {
        let myfileter = datalocal.filter(
          (list, index) =>
            hasil.findIndex((list2, index2) => index2 === index) > -1
        );
        myfileter.forEach((row) => {
          let databaru = getItem("data_trancate_penjualan_baru") || [];
          let hasil_cek = databaru.findIndex(
            (list) => list.no_faktur_jual === row.no_faktur_jual
          );
          if (hasil_cek === -1) {
            let hasil = {
              ...row
            };
            databaru.push(hasil);
            setItem("data_trancate_penjualan_baru", databaru);
          }
        });
      }
    } else {
      removeItem("data_trancate_penjualan_baru");
      removeItem("data_trancate_pembelian_baru");

      this.setState({
        selected: []
      });
    }
  }

  truncate() {
    this.setState({
      isLoading: true
    });
    if (getItem("trx") === "PENJUALAN") {
      let data = getItem("data_trancate_penjualan_baru")
        .filter((cek) => cek.status_cek === true)
        .map((hasil) => {
          let row = {
            ...hasil
          };
          return row;
        });
      // console.log(data)
      // return false
      let hasil = [];

      if (data.length === 0) {
        ToastNotification("info", "Data Backup Belum Tersedia");
        setTimeout(() => {
          this.setState({
            isLoading: false
          });
        }, 3000);
      } else {
        data.forEach((el, index) => {
          let row = {
            "no generate": el.no_generate,
            "no fakturgroup": el.no_faktur_group,
            "no fakturgroupuser": el.no_faktur_group_user || "-",
            "no fakturjual": el.no_faktur_jual,
            "tgl system": el.tgl_system.toString(),
            "kode sales": el.kode_sales,
            "kode member": el.kode_member,
            "nama customer": el.nama_customer.replaceAll(",", ""),
            "no hp": el.no_hp,
            "alamat customer": el.alamat_customer.replaceAll(",", ""),
            // "alamat customer": el.alamat_customer,
            "kode gudang": el.kode_gudang,
            "nama sales": el.nama_sales.replaceAll(",", ""),
            "kode baki": el.kode_toko || "-",
            "kode barcode":
              el.kode_barcode === "-"
                ? "-"
                : String(el.kode_barcode).padStart("8", "0"),
            "kode barang":
              el.kode_barcode === "-"
                ? "-"
                : String(el.kode_barcode).padStart("8", "0"),
            "kode group": el.kode_group,
            "kode dept": el.kode_dept,
            "kode marketplace": el.kode_marketplace,
            "nama barang": el.nama_barang.replaceAll(",", " "),
            "kadar cetak": el.kadar_cetak || 0,
            "berat awal": parseFloat(el.berat_awal),
            berat: parseFloat(el.berat),
            "nama atribut": el.nama_atribut.replaceAll(",", " "),
            "berat atribut": parseFloat(el.berat_atribut),
            "harga jual": parseInt(el.harga_jual),
            "harga gram": parseInt(el.harga_gram),
            "harga atribut": parseInt(el.harga_atribut),
            ongkos: parseInt(el.ongkos),
            "harga total": parseInt(el.harga_total),
            "input by": el.input_by || "-",
            "status kembali": el.status_kembali || "-",
            "status valid": el.status_valid || "-",
            "status premi": el.status_premi || "-",
            "status tukar": el.status_tukar || false,
            "no titipgroup": el.no_titip_group || "-",
            "no pesanan": el.no_pesanan || "-",
            jam: el.jam || "-",
            curr: parseInt(el.curr || 0),
            "total berlianrp": parseInt(el.total_berlian_rp || 0),
            "kode intern": el.kode_intern || "-"
          };
          hasil.push(row);
        });
        console.log(hasil);
        JSONToCSVConvertor(hasil, "penjualan", true);
        setTimeout(() => {
          this.setState({
            isLoading: true
          });
          this.hapus();
        }, 300);
      }
      // setTimeout(() => {
      //   this.setState({
      //     isLoading: true
      //   });
      //   this.hapus();
      // }, 300);
    } else {
      let data = getItem("data_trancate_pembelian_baru")
        .filter((cek) => cek.status_cek === true)
        .map((hasil) => {
          let row = {
            ...hasil
          };
          return row;
        });

      let hasil = [];
      if (data.length === 0) {
        ToastNotification("info", "Data Backup Belum Tersedia");
        setTimeout(() => {
          this.setState({
            isLoading: false
          });
        }, 3000);
      } else {
        data.forEach((el) => {
          let row = {
            no_fakturgroup: el.no_faktur_group,
            no_fakturbeli: el.no_faktur_beli,
            tgl_system: el.tgl_system,
            kode_sales: el.kode_sales,
            kode_member: el.kode_member,
            nama_customer: el.nama_customer.replaceAll(",", ""),
            no_hp: el.no_hp,
            alamat_customer: el.alamat_customer.replaceAll(",", ""),
            // kadar: el.kadar,
            kode_gudang: el.kode_gudang,
            kode_group: el.kode_group,
            kode_dept: el.kode_dept,
            kode_barcode:
              el.kode_barcode === "-"
                ? "-"
                : String(el.kode_barcode).padStart("8", "0"),
            nama_barang: el.nama_barang.replaceAll(",", ""),
            berat_nota: parseFloat(el.berat_nota),
            berat: parseFloat(el.berat),
            harga_nota: parseInt(el.harga_nota),
            harga: parseInt(el.harga),
            harga_rata: parseInt(el.harga_rata),
            kondisi: el.kondisi.replaceAll(",", ""),
            // cash_rp: parseInt(el.cash_rp),
            input_by: el.input_by || "-",
            status_valid: el.status_valid || false
          };
          hasil.push(row);
        });
        console.log(hasil);
        JSONToCSVConvertor(hasil, "pembelian", true);
        setTimeout(() => {
          this.setState({
            isLoading: true
          });
          this.hapus();
        }, 300);
      }
    }
  }

  filterData(e) {
    setItem("filter_status", e);
    let trx = getItem("trx");
    // console.log(trx)
    if (trx === "PENJUALAN") {
      if (e === "GOLD") {
        let filter = getItem("data_trancate_penjualan").filter(
          (list) => list.status_gold === "GOLD"
        );
        this.setState({
          databarang: filter
        });
      } else if (e === "NONGOLD") {
        let filter = getItem("data_trancate_penjualan").filter(
          (list) => list.status_gold === "NONGOLD"
        );
        this.setState({
          databarang: filter
        });
      } else {
        this.setState({
          databarang: getItem("data_trancate_penjualan")
        });
      }
    } else {
      if (e === "GOLD") {
        let filter = getItem("data_trancate_pembelian").filter(
          (list) => list.status_gold === "GOLD"
        );
        this.setState({
          databarang: filter
        });
      } else if (e === "NONGOLD") {
        let filter = getItem("data_trancate_pembelian").filter(
          (list) => list.status_gold === "NONGOLD"
        );
        this.setState({
          databarang: filter
        });
      } else {
        this.setState({
          databarang: getItem("data_trancate_pembelian")
        });
      }
    }
  }

  render() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selected,
      style: { backgroundColor: "#c8e6c9" },
      onSelect: (row, isSelect, rowIndex, e) => {
        const index = row.no;
        this.handleOnSelect(row, isSelect, index, e);
      },
      onSelectAll: (isSelect, data, rowIndex, e) => {
        this.handleOnSelectAll(isSelect, data, rowIndex, e);
      }
      // onSelect: this.handleOnSelect,
      // onSelectAll: this.handleOnSelectAll,
    };

    return (
      <FormPanel menu="Backup" submenu="Backup">
        <div className="row">
          <div className="col-3 text-left">
            <Field
              name="status_gold"
              component={ReanderSelect}
              options={[
                {
                  value: "ALL",
                  name: "SEMUA"
                },
                {
                  value: "GOLD",
                  name: "GOLD"
                },
                {
                  value: "NONGOLD",
                  name: "NONGOLD"
                }
              ]}
              type="text"
              onChange={(e) => this.filterData(e)}
              label="Status Gold"
              placeholder="Pilih Status Gold"
            />
          </div>
          <div className="col-9 mt-4 text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.modalTruncate()}
            >
              {" "}
              Filter Backup{" "}
            </button>
          </div>
        </div>
        <br />
        {/* {console.log(getItem('trx') === "PENJUALAN" ? 'PENJUALAN' : "PEMBELIAN")} */}
        <Tabel
          selectRow={selectRow}
          keyField="no"
          data={this.state.databarang}
          columns={
            getItem("trx") === "PENJUALAN"
              ? this.state.columns
              : this.state.columnsPembelian
          }
          empty={true}
          textEmpty={
            getItem("trx") === "PENJUALAN"
              ? "Data Backup Penjualan"
              : "Data Backup Pembelian"
          }
        />

        {getItem("trx") === "PENJUALAN" ? (
          <TotalPenjualan
            isLoading={this.state.isLoading}
            clear={() => this.hapus()}
            truncate={() => this.truncate()}
          />
        ) : (
          <TotalPembelian
            isLoading={this.state.isLoading}
            clear={() => this.hapus()}
            truncate={() => this.truncate()}
          />
        )}

        <ModalGlobal
          size="xl"
          title={"Filter Backup"}
          content={
            <HeadModalTrancate
              isLoading={this.state.isLoading}
              onSubmit={(data) => this.handleSubmit(data)}
            />
          }
        />
      </FormPanel>
    );
  }
}

Truncate = reduxForm({
  form: "Truncate",
  enableReinitialize: true
})(Truncate);
export default connect(null)(Truncate);
